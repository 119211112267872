<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">
                                产品名称：
                            </td>
                            <td>
                                <el-input v-model="searchItem.materialName" size="small"
                                          placeholder="请输入物料名称"></el-input>
                            </td>
                            <td style="width: 80px">
                                适用机型：
                            </td>
                            <td>
                                <el-select v-model="searchItem.useType" size="small" style="width: 100%"
                                           placeholder="请选择适用机型">
                                    <el-option
                                            v-for="item in useTypeList"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px">
                                使用性质：
                            </td>
                            <td>
                                <el-select v-model="searchItem.useNature" size="small" style="width: 100%"
                                           placeholder="请选择使用性质">
                                    <el-option
                                            v-for="item in useNatureList"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 80px;padding-top: 10px">
                                部件：
                            </td>
                            <td>
                                <el-select v-model="searchItem.component" size="small"
                                           style="padding-top: 10px;width: 100%"
                                           filterable
                                           placeholder="请选择部件">
                                    <el-option
                                            v-for="item in componentList"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </td>
                            <td>物料编码：</td>
                            <td>
                                <el-input v-model="searchItem.materialCode" size="small"
                                          placeholder="请输入物料编码"></el-input>
                            </td>
                            <td style="width: 300px ;text-align: center;padding-top: 10px" colspan="2">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" style="margin-left: 10%" size="small"
                                           @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-circle-plus" style="margin-left: 10%"
                                           v-if="hasAuthority('material_add')"
                                           size="small" @click="addObject">
                                    增加
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                            <el-table-column prop="materialCode" label="物料编码" width="180"
                                             align="center"></el-table-column>
                            <el-table-column prop="warehouseTypeStr" label="所属仓库" width="180" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="useTypeName" label="适用机型" width="100" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="componentName" label="部件" width="100" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="useNatureName" label="使用性质" width="100" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="materialName" label="产品名称" width="180" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="model" label="型号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="parameter" label="参数" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="unitName" label="单位" width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" width="100" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" title="编辑" v-if="hasAuthority('material_update')"
                                           @click="editMaterial(scope.row.id)"></i>
                                        <i class="iconfont iconshanchu" title="删除"
                                           v-if="hasAuthority('material_delete')"
                                           @click="deleteMaterial(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-form">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="editDialogVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <el-form :model="materialForm" :rules="materialRules" ref="materialForm" label-width="100px"
                         class="demo-ruleForm">
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="物料编码:" prop="materialCode">
                                <el-input size="small" v-if="type == 0"
                                          v-model="materialForm.materialCode"></el-input>
                                <el-input size="small" v-else v-model="materialForm.materialCode" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="产品名称:" prop="materialName">
                                <el-input size="small" v-model="materialForm.materialName"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="型号:" prop="model">
                                <el-input size="small" v-model="materialForm.model"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="产品参数:" prop="parameter">
                                <el-input size="small" v-model="materialForm.parameter"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="仓库类型:" prop="checkedDepot">
                                <el-checkbox-group v-model="checkedDepotList" @change="handleCheckedDepotChange">
                                    <el-checkbox v-for="listSetting in depotTypes" :label="listSetting.value"
                                                 :key="listSetting.label">
                                        {{listSetting.label}}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="适用机型:" prop="checkedMachine">
                                <el-checkbox-group v-model="checkedMachineList" @change="handleCheckedMachineChange">
                                    <el-checkbox v-for="listSetting in useTypeList" :label="listSetting.wholeCode"
                                                 :key="listSetting.wholeCode">
                                        {{listSetting.name}}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="部件:" prop="component">
                                <el-select v-model="materialForm.component" size="small" style="width: 100%"
                                           filterable placeholder="请选择部件">
                                    <el-option
                                            v-for="item in componentList"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="使用性质:" prop="useNature">
                                <el-select v-model="materialForm.useNature" size="small" style="width: 100%"
                                           placeholder="请选择使用性质">
                                    <el-option
                                            v-for="item in useNatureList"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="单位:" prop="unit">
                                <el-select v-model="materialForm.unit" size="small" style="width: 100%"
                                           placeholder="请选择单位">
                                    <el-option
                                            v-for="item in unitList"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-col :span="10" style="width: 100%" :style="{display: isDisplay}">
                                <el-form-item size="small" label="安全库存:" prop="safeQuantity">
                                    <el-input size="small" v-model.number="materialForm.safeQuantity"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel('materialForm')">取 消</el-button>
                    <el-button type="primary" @click="submit('materialForm')" :loading="isLoad">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                tableData: [],
                option: {
                    pageSize: 15,
                    pageIndex: 11,
                },
                totalPage: 10,
                editDialogVisible: false,
                isLoad: false,
                lineHeight: '',
                editTitle: '',
                isDisplay: 'none',
                searchItem: {
                    materialName: '',
                    useType: '',
                    useNature: '',
                    component: '',
                    pageIndex: 1,
                    pageSize: 15
                },
                useTypeList: [],
                useNatureList: [],
                componentList: [],
                unitList: [],
                depotType: 0,
                materialForm: {
                    id: null,
                    materialCode: '',
                    materialName: '',
                    useType: '',
                    component: '',
                    useNature: '',
                    unit: '',
                    model: '',
                    parameter: '',
                    useLevel: '',
                    safeQuantity: 0,
                    warehouseTypeStr: '',
                },
                currentPage: '',
                materialRules: {
                    materialCode: [
                        {required: true, message: '请输入物料编码', trigger: 'blur'},
                    ],
                    materialName: [{required: true, message: '请输入物料名称', trigger: 'blur'}],
                    component: [{required: true, message: '请输入物料部件', trigger: 'blur'}],
                    useNature: [{required: true, message: '请输入物料使用性质', trigger: 'blur'}],
                    unit: [{required: true, message: '请输入物料单位', trigger: 'blur'}],
                },
                checkedDepotList: [],
                depotTypes: [
                    {
                        value: 0,
                        label: '主物料仓库'
                    },
                    {
                        value: 1,
                        label: '研发物料仓库'
                    },
                    {
                        value: 2,
                        label: '外协仓库'
                    },
                    {
                        value: 3,
                        label: '售后仓库'
                    }
                ],
                checkedMachineList: [],
                machineTypes: [
                    {
                        value: 0,
                        label: '塔机'
                    },
                    {
                        value: 1,
                        label: '升降机'
                    },
                    {
                        value: 2,
                        label: '小车盲吊'
                    }
                ],
                valueDepotList: [],
                valueMachineList: [],
                type: 0,
            };
        },
        methods: {
            getDictionary() {
                this.$api.getDictionaryData().then(res => {
                    if (res.code == 200) {
                        this.useTypeList = res.data.useTypeList;
                        this.componentList = res.data.componentList;
                        this.useNatureList = res.data.useNatureList;
                        this.unitList = res.data.unitList;
                    }
                })
            },
            pageChange(option) {
                this.option.pageIndex = option;
                this.currentPage = option
                this.searchItem.pageIndex = option
                this.$api.getMaterial(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data.content) {
                            var warehouseStr = '';
                            for (var re of item.warehouseTypeSet) {
                                if (re == 0) {
                                    if (warehouseStr == '') {
                                        warehouseStr = '主物料仓库'
                                    } else {
                                        warehouseStr = warehouseStr + ', ' + '主物料仓库'
                                    }
                                } else if (re == 1) {
                                    if (warehouseStr == '') {
                                        warehouseStr = '研发物料仓库'
                                    } else {
                                        warehouseStr = warehouseStr + ', ' + '研发物料仓库'
                                    }
                                } else if (re == 2) {
                                    if (warehouseStr == '') {
                                        warehouseStr = '外协仓库'
                                    } else {
                                        warehouseStr = warehouseStr + ', ' + '外协仓库'
                                    }
                                } else {
                                    if (warehouseStr == '') {
                                        warehouseStr = '售后仓库'
                                    } else {
                                        warehouseStr = warehouseStr + ', ' + '售后仓库'
                                    }
                                }
                            }
                            item.warehouseTypeStr = warehouseStr;
                        }
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    } else {
                        this.$message.error('连接服务器失败,请联系管理员')
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.getMaterial(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data.content) {
                            var warehouseStr = '';
                            for (var re of item.warehouseTypeSet) {
                                if (re == 0) {
                                    if (warehouseStr == '') {
                                        warehouseStr = '主物料仓库'
                                    } else {
                                        warehouseStr = warehouseStr + ', ' + '主物料仓库'
                                    }
                                } else if (re == 1) {
                                    if (warehouseStr == '') {
                                        warehouseStr = '研发物料仓库'
                                    } else {
                                        warehouseStr = warehouseStr + ', ' + '研发物料仓库'
                                    }
                                } else if (re == 2) {
                                    if (warehouseStr == '') {
                                        warehouseStr = '外协仓库'
                                    } else {
                                        warehouseStr = warehouseStr + ', ' + '外协仓库'
                                    }
                                } else {
                                    if (warehouseStr == '') {
                                        warehouseStr = '售后仓库'
                                    } else {
                                        warehouseStr = warehouseStr + ', ' + '售后仓库'
                                    }
                                }
                            }
                            item.warehouseTypeStr = warehouseStr;
                        }
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    } else {
                        this.$message.error('连接服务器失败,请联系管理员')
                    }
                })
            },
            editMaterial(id) {
                this.editDialogVisible = true;
                this.editTitle = '编辑物料信息'
                this.checkedMachineList = [];
                this.valueMachineList = [];
                this.valueDepotList = [];
                this.checkedDepotList = [];
                this.type = 1;
                var flag = false;
                this.$api.getMaterialById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.materialForm = {
                            id: res.data.id,
                            materialCode: res.data.materialCode,
                            materialName: res.data.materialName,
                            useType: res.data.useType,
                            component: res.data.component,
                            useNature: res.data.useNature,
                            unit: res.data.unit,
                            model: res.data.model,
                            parameter: res.data.parameter,
                            useLevel: res.data.useLevel,
                            safeQuantity: res.data.safeQuantity
                        }
                        this.checkedMachineList = res.data.useType.split('|')
                        this.checkedDepotList = res.data.warehouseTypeSet
                        for (var item of this.checkedDepotList) {
                            if (item == 0) {
                                flag = true;
                            }
                            var one = {value: ''}
                            one.value = item;
                            this.valueDepotList.push(one)
                        }
                        for (var item of this.checkedMachineList) {
                            var one = {wholeCode: ''}
                            one.wholeCode = item;
                            this.valueMachineList.push(one)
                        }
                        if (flag) {
                            this.isDisplay = 'block';
                        } else {
                            this.isDisplay = 'none';
                        }
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            deleteMaterial(id) {
                this.$confirm('你确定要删除此物料信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.deleteMaterial({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange(this.currentPage)
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            },
            reset() {
                this.searchItem = {
                    materialName: '',
                    useType: '',
                    useNature: '',
                    component: '',
                    pageIndex: 1,
                    pageSize: 15
                }
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
            addObject() {
                this.editDialogVisible = true;
                this.editTitle = '新增物料信息';
                this.type = 0;
                this.isDisplay = 'none';
                this.isLoad = false;
                this.checkedMachineList = [];
                this.valueMachineList = [];
                this.valueDepotList = [];
                this.checkedDepotList = [];
                this.materialForm = {
                    id: null,
                    materialCode: '',
                    materialName: '',
                    useType: '',
                    component: '',
                    useNature: '',
                    unit: '',
                    model: '',
                    parameter: '',
                    useLevel: '',
                    safeQuantity: null
                }
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.editDialogVisible = false;
                this.isLoad = false;
            },
            submit(formName) {
                this.isLoad = true;
                var warehouseStr = '';
                var machineStr = '';
                if (this.materialForm.safeQuantity == 0) {
                    this.materialForm.safeQuantity = 0 + ''
                }
                debugger;
                if (this.materialForm.materialCode.length != 6
                    || isNaN(this.materialForm.materialCode * 1)) {
                    this.$message.error('物料编码只能输入6位数字');
                    this.isLoad = false;
                    return;
                }
                if (this.valueDepotList.length < 1) {
                    this.$message.error('所属仓库不能为空');
                    this.isLoad = false;
                    return;
                } else {
                    for (var item of this.valueDepotList) {
                        if (item.value == 0) {
                            if (this.materialForm.safeQuantity == '' || this.materialForm.safeQuantity == undefined) {
                                this.$message.error('安全库存不能为空');
                                this.isLoad = false;
                                return;
                            }
                        }
                        if (warehouseStr != '') {
                            warehouseStr = warehouseStr + '|' + item.value;
                        } else {
                            warehouseStr = item.value + ''
                        }
                    }
                }
                if (this.valueMachineList.length < 1) {
                    this.$message.error('所属适用机型不能为空');
                    this.isLoad = false;
                    return;
                } else {
                    for (var item of this.valueMachineList) {
                        if (machineStr != '') {
                            machineStr = machineStr + '|' + item.wholeCode;
                        } else {
                            machineStr = item.wholeCode
                        }
                    }
                }
                debugger;
                if ((this.materialForm.materialCode + '').length != 6) {
                    this.$message.error('物料编码的只能是6位的数字');
                    this.isLoad = false;
                    return;
                }
                this.materialForm.useType = machineStr
                this.materialForm.warehouseTypeStr = warehouseStr;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.type == 0) {
                            this.$api.addMaterial(this.materialForm).then(res => {
                                if (res.code == 200) {
                                    this.isLoad = false;
                                    this.pageChange(1)
                                    this.editDialogVisible = false;
                                    this.$message.success(res.message)
                                } else {
                                    this.isLoad = false;
                                    this.$message.error(res.message)
                                }
                            })
                        } else {
                            this.$api.updateMaterial(this.materialForm).then(res => {
                                if (res.code == 200) {
                                    this.isLoad = false;
                                    this.pageChange(this.currentPage)
                                    this.editDialogVisible = false;
                                    this.$message.success(res.message)
                                } else {
                                    this.isLoad = false;
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    } else {
                        this.isLoad = false;
                    }
                });
            },
            handleCheckedDepotChange(value) {
                if (value.indexOf(0) > -1) {
                    this.isDisplay = 'block';
                } else {
                    this.isDisplay = 'none';
                }
                this.valueDepotList = [];
                let checkedCount = value.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.depotTypes.length;
                for (var listSetting of this.depotTypes) {
                    for (var checkedDepotList of value) {
                        if (listSetting.value == checkedDepotList) {
                            this.valueDepotList.push(listSetting);
                        }
                    }
                }
            },
            handleCheckedMachineChange(value) {
                this.valueMachineList = [];
                let checkedCount = value.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.useTypeList.length;
                for (var listSetting of this.useTypeList) {
                    for (var checkedMachineList of value) {
                        if (listSetting.wholeCode == checkedMachineList) {
                            this.valueMachineList.push(listSetting);
                        }
                    }
                }
            },
        },
        created() {
            this.getDictionary();
            this.pageChange(1)
            this.tableHeight = (window.innerHeight) * 0.71
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .iconbianji {
        margin-right: 10px;
        color: #20a0ff;
    }

    .iconshanchu {
        margin-left: 10px;
        color: red;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 100px;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    td {
        text-align: right;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

</style>